(function($) {
	$(document).ready(function() {

        // Input file
        $('input[type=file]').bootstrapFileInput();

        //Scrollbar
        $('.reg, .custom-open').mCustomScrollbar({
            mouseWheelPixels: 150
        });

        // Custom select
        function e(e, t, n, r) {
            return e % 10 < 5 && e % 10 > 0 && e % 100 < 5 || e % 100 > 20 ? e % 10 > 1 ? n : t : r
        }
        var lang = {
            errorLoading: function () {
                return "Невозможно загрузить результаты"
            }, inputTooLong: function (t) {
                var n = t.input.length - t.maximum, r = "Пожалуйста, введите на " + n + " символ";
                return r += e(n, "", "a", "ов"), r += " меньше", r
            }, inputTooShort: function (t) {
                var n = t.minimum - t.input.length, r = "Пожалуйста, введите еще хотя бы " + n + " символ";
                return r += e(n, "", "a", "ов"), r
            }, loadingMore: function () {
                return "Загрузка данных…"
            }, maximumSelected: function (t) {
                var n = "Вы можете выбрать не более " + t.maximum + " элемент";
                return n += e(t.maximum, "", "a", "ов"), n
            }, noResults: function () {
                return "Совпадений не найдено"
            }, searching: function () {
                return "Поиск…"
            }
        }
        $("select:not(.emp-colorpicker)").select2({minimumResultsForSearch: -1, language: lang});
        $(".select-search").select2({ language: lang });
        $(".select-check").select2({maximumSelectionLength: 99, language: lang});

        //Grains
        $('.grains .btn').click(function () {
            $( ".fa-grain" ).animate({
                opacity: 0,
                top: "600px"
            }, 2000);

            setTimeout(function() {
                $('.footer').addClass('trees');
                $('.grains').remove();
            }, 2000 );

        });

        //Tabs
        $('ul.nav-tabs a').click(function (e) {
            e.preventDefault();
            $(this).tab('show');
        });
        $('.stacktable').stacktable({myClass:'your-custom-class'});

        //Train address
        if ($(window).width() < 1367) {
            function resize() {
                var body_width = $('body').width();
                $('.train-address').each(function(i) {
                    var current_width = $(this).width(),
                        new_width = (body_width - current_width) * .5,
                        new_left = (body_width / $('.train-address').length * i - 540) + new_width;
                    $(this).css('left',new_left);
                });
            }
            resize();
            $(window).resize(resize).trigger('resize');
            $(window).resize(function(){
                $('.train-address').css('margin-left', 0);
            });
        }
        $(window).resize(function(){
            if ($(window).width() < 1367) {
                function resize() {
                    var body_width = $('body').width();
                    $('.train-address').each(function(i) {
                        var current_width = $(this).width(),
                            new_width = (body_width - current_width) * .5,
                            new_left = (body_width / $('.train-address').length * i - 540) + new_width;
                        $(this).css('left',new_left);
                    });
                }
                resize();
                $(window).resize(resize).trigger('resize');
                $(window).resize(function(){
                    $('.train-address').css('margin-left', 0);
                });
            }
        });

        //Logo
        if ($(window).width() > 992) {
            var stage_left = 0;
            var stage_top = 10;
            $('.home.applicant #logo').sprite({fps: 1, no_of_frames: 14})
            .spRandom({
                top: 10,
                left: stage_left + 300,
                right: 50,
                bottom: 40,
                speed: 3500,
                pause: 500
            });
        }
        $(window).resize(function(){
            if ($(window).width() > 992) {
                var stage_left = 0;
                var stage_top = 10;
                $('.home.applicant #logo').sprite({fps: 1, no_of_frames: 14})
                .spRandom({
                    top: 10,
                    left: stage_left + 300,
                    right: 50,
                    bottom: 40,
                    speed: 3500,
                    pause: 500
                });
            }
        });

        //Registration in slogan
        $(".slogan-box .btn").click(function(){
            $(".slogan-box").hide();
            $(".in-slogan").show();
            return false;
        });
        $('.private-close').click(function(){
            console.log('clicked close');
            $(".slogan-box").show();
            $(".in-slogan").hide();
            return false;
        })



    });
})(jQuery);